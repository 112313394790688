html, body {
  font-family: "Lora", serif;
  overflow-x: hidden;
  font-size: 14px; }
  html a, body a {
    color: black; }

.background-beige {
  background-color: #f8f8f7; }

h1 {
  font-size: 35px;
  font-weight: normal; }

section {
  opacity: 0;
  transition: opacity 2s; }

section.visible {
  opacity: 1; }

.underlinable {
  display: inline-block; }
  .underlinable:after {
    content: ' ';
    background-image: url("/assets/img/icon_line.png");
    background-size: cover;
    display: block;
    width: 0%;
    height: 8px;
    transition: ease all 0.5s; }
  .underlinable:hover:after {
    width: 100%; }
  .underlinable.project:after {
    background-image: url("/assets/img/project-line.png");
    height: 2px; }
  .underlinable.about:after {
    background-image: url("/assets/img/about-line.png");
    height: 2px; }

.padding-left-none {
  padding-left: 0px; }

.padding-right-none {
  padding-right: 0px; }

.social-icons {
  margin-top: 50px;
  text-align: center; }
  .social-icons a img {
    width: 35px;
    margin: 0 10px; }

.container {
  max-width: 1440px; }

.no-gutters {
  margin-left: -15px !important;
  margin-right: -15px !important; }

.homepage .project-cards {
  padding-bottom: 100px; }
  .homepage .project-cards .project-card {
    display: block;
    margin: 0; }
    .homepage .project-cards .project-card img, .homepage .project-cards .project-card video {
      width: 100%;
      margin-bottom: 20px; }
    .homepage .project-cards .project-card h3 {
      font-size: 25px;
      line-height: 32px; }
    .homepage .project-cards .project-card .subtitle {
      display: block;
      font-family: "Lora", serif;
      font-style: italic;
      color: #A2A2A2; }
    .homepage .project-cards .project-card:hover {
      text-decoration: none;
      color: black; }
  .homepage .project-cards .card-container.dummy-card {
    opacity: 0 !important; }

@media (max-width: 425px) {
  .homepage .main-banner {
    padding: 25vh 0;
    margin-bottom: -100px; }
    .homepage .main-banner h1 {
      margin: 0 auto;
      width: 70vw;
      text-align: center; }
  .homepage .project-cards {
    padding-bottom: 20px; }
    .homepage .project-cards .card-container .project-card {
      margin: 20px 0; }
    .homepage .project-cards .card-container.dummy-card {
      display: none; } }

@media (min-width: 426px) and (max-width: 768px) {
  .homepage .main-banner {
    padding: 25vh 0;
    margin-bottom: -100px; }
    .homepage .main-banner h1 {
      margin: 0 auto;
      width: 90vw;
      text-align: center; }
  .homepage .project-cards .card-container .project-card {
    margin: 50px 0; } }

@media (min-width: 769px) and (max-width: 1024px) {
  .homepage .main-banner {
    padding: 25vh 0;
    margin-bottom: -100px; }
    .homepage .main-banner h1 {
      margin: 0 auto;
      width: 90vw;
      text-align: center; }
  .homepage .project-cards .big-small .card-container:nth-child(2) .project-card {
    float: right;
    margin: 200px 0 0 50px; }
  .homepage .project-cards .small-big .card-container:nth-child(1) .project-card {
    margin: 25px 50px 0 0; }
  .homepage .project-cards .small-big .card-container:nth-child(2) .project-card {
    margin: 200px 0px 0 0; } }

@media (min-width: 1025px) {
  .homepage .main-banner {
    padding: 25vh;
    margin-bottom: -100px; }
    .homepage .main-banner h1 {
      margin: 0 auto;
      width: 50vw;
      text-align: center; }
  .homepage .project-cards .big-small .card-container:nth-child(2) .project-card {
    float: right;
    margin: 200px 0 0 50px; }
  .homepage .project-cards .small-big .card-container:nth-child(1) .project-card {
    margin: 25px 50px 0 0; }
  .homepage .project-cards .small-big .card-container:nth-child(2) .project-card {
    margin: 200px 0px 0 0; } }

.about .main-banner {
  padding: 0; }
  .about .main-banner h1 {
    margin-top: 40%;
    font-size: 35px;
    line-height: 50px;
    width: 80%;
    float: right; }
  .about .main-banner .about-container {
    max-width: 1440px;
    margin: 0 auto; }

.about .icons {
  margin-top: 100px;
  text-align: center; }
  .about .icons .icon {
    display: inline-block;
    text-align: center;
    width: 200px; }
    .about .icons .icon img {
      width: 65px;
      margin-bottom: 15px; }
    .about .icons .icon label {
      display: block;
      text-transform: uppercase;
      font-family: "Muli", sans-serif;
      font-weight: bold;
      letter-spacing: 2px;
      line-height: 18px; }

.about .about-me {
  margin-top: 100px;
  padding: 0 25px; }
  .about .about-me h3 {
    font-size: 35px;
    line-height: 50px; }
  .about .about-me p {
    font-size: 16px;
    line-height: 26px;
    font-family: "Muli", sans-serif; }

.about .testimonials {
  background-color: #EEE6E1;
  padding: 100px 0 0 0;
  margin: 75px 0; }
  .about .testimonials .section-header {
    text-transform: uppercase;
    letter-spacing: 5px;
    line-height: 32px;
    font-size: 20px;
    text-align: center;
    font-family: "Muli", sans-serif;
    margin-bottom: 25px; }
  .about .testimonials .scroller-container {
    overflow: hidden;
    padding-bottom: 75px; }
  .about .testimonials .testimonials-container {
    max-width: 1120px;
    margin: 0 auto; }
  .about .testimonials .scroller .testimonial {
    float: left; }
    .about .testimonials .scroller .testimonial.active {
      display: block; }
    .about .testimonials .scroller .testimonial h2 {
      text-align: center;
      margin: 0 25px;
      line-height: 50px;
      font-size: 35px; }
  .about .testimonials .from {
    text-transform: uppercase;
    font-family: "Muli", sans-serif;
    text-align: center;
    display: block;
    margin: 30px 0 0 0; }
  .about .testimonials .arrow-box {
    width: 120px;
    height: 120px;
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer; }
    .about .testimonials .arrow-box.left {
      background-image: url("/assets/img/icon_arrow_left.png");
      background-size: 30%; }
    .about .testimonials .arrow-box.right {
      float: right;
      background-image: url("/assets/img/icon_arrow_right.png");
      background-size: 30%; }
  .about .testimonials .bullets {
    text-align: center;
    margin-top: 50px; }
    .about .testimonials .bullets .bullet {
      display: inline-block;
      height: 25px;
      width: 25px;
      background-repeat: no-repeat;
      background-size: 50%;
      background-position: center center; }
      .about .testimonials .bullets .bullet.active {
        background-size: 100%; }
      .about .testimonials .bullets .bullet.style-1 {
        background-image: url("/assets/img/icon_testimonials_1_small.png"); }
        .about .testimonials .bullets .bullet.style-1.active {
          background-image: url("/assets/img/icon_testimonials_1_large.png"); }
      .about .testimonials .bullets .bullet.style-2 {
        background-image: url("/assets/img/icon_testimonials_2_small.png"); }
        .about .testimonials .bullets .bullet.style-2.active {
          background-image: url("/assets/img/icon_testimonials_2_large.png"); }
      .about .testimonials .bullets .bullet.style-3 {
        background-image: url("/assets/img/icon_testimonials_3_small.png"); }
        .about .testimonials .bullets .bullet.style-3.active {
          background-image: url("/assets/img/icon_testimonials_3_large.png"); }
      .about .testimonials .bullets .bullet.style-4 {
        background-image: url("/assets/img/icon_testimonials_4_small.png"); }
        .about .testimonials .bullets .bullet.style-4.active {
          background-image: url("/assets/img/icon_testimonials_4_large.png"); }
      .about .testimonials .bullets .bullet.style-5 {
        background-image: url("/assets/img/icon_testimonials_5_small.png"); }
        .about .testimonials .bullets .bullet.style-5.active {
          background-image: url("/assets/img/icon_testimonials_5_large.png"); }

.about .past-work {
  margin: 75px 0;
  font-family: "Muli", sans-serif; }
  .about .past-work .section-header {
    text-transform: uppercase;
    letter-spacing: 5px;
    line-height: 18px;
    font-size: 20px;
    text-align: center;
    font-family: "Muli", sans-serif;
    margin-bottom: 40px; }
  .about .past-work .works {
    margin-top: 50px; }
    .about .past-work .works .work {
      height: 150px;
      margin-bottom: 30px;
      background-size: cover;
      background-position: center center; }
  .about .past-work p {
    font-size: 16px;
    line-height: 26px;
    text-align: center; }

.about .awards {
  padding: 75px 0;
  margin: 75px 0 0 0;
  background-color: #F8F8F7;
  font-family: "Muli", sans-serif; }
  .about .awards .section-header {
    text-transform: uppercase;
    letter-spacing: 5px;
    line-height: 18px;
    font-size: 20px;
    text-align: center;
    font-family: "Muli", sans-serif;
    margin-bottom: 50px; }
  .about .awards ul {
    list-style: none;
    list-style-type: none;
    margin: 0;
    padding: 0; }
    .about .awards ul li {
      list-style: none;
      display: block;
      text-align: center;
      font-size: 16px;
      line-height: 20px;
      padding: 8px 0; }

@media (max-width: 425px) {
  .about .main-banner .about-container {
    padding: 35px; }
  .about .main-banner h1 {
    font-size: 30px;
    float: none;
    width: 100%;
    margin: 120px 0 80px 0; }
  .about .icons .container {
    max-width: 100% !important; }
    .about .icons .container .icon {
      width: 125px;
      margin-bottom: 15px; }
  .about .about-me {
    margin-top: 50px; }
    .about .about-me h3, .about .about-me p {
      margin-bottom: 40px; }
  .about .testimonials .arrow-box {
    width: 84px;
    height: 84px; }
    .about .testimonials .arrow-box.left, .about .testimonials .arrow-box.right {
      background-size: 20%; }
  .about .testimonials .scroller .testimonial h2 {
    font-size: 25px;
    line-height: 30px; }
  .about .testimonials .bullets {
    margin-top: 25px; } }

@media (min-width: 426px) and (max-width: 768px) {
  .about .main-banner .about-container {
    padding: 100px 50px; }
  .about .main-banner h1 {
    margin-top: 20%;
    font-size: 35px;
    line-height: 45px;
    width: 450px;
    position: absolute;
    margin-left: 5px;
    z-index: 98; }
  .about .icons .container {
    max-width: 100% !important; }
    .about .icons .container .icon {
      width: 140px; }
  .about .past-work .works .work {
    height: 250px; } }

@media (min-width: 769px) and (max-width: 1024px) {
  .about .main-banner {
    padding: 0; }
    .about .main-banner h1 {
      margin-top: 30%;
      font-size: 35px;
      line-height: 45px;
      width: 80%;
      float: right;
      margin-right: 8%; }
  .about .icons .container .icon {
    width: 175px; } }

.project .project-section img, .project .project-section video {
  width: 100%; }

.project .project-section .content-block {
  display: flex;
  align-items: center; }
  .project .project-section .content-block .content {
    margin: 50px; }
    .project .project-section .content-block .content h1 {
      font-family: "Muli", sans-serif;
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 4px;
      text-transform: uppercase;
      margin: 25px 0;
      font-size: 16px; }
    .project .project-section .content-block .content h3 {
      font-size: 35px;
      line-height: 45px;
      margin: 20px 0; }
    .project .project-section .content-block .content p {
      font-family: "Muli", sans-serif;
      font-size: 16px;
      line-height: 26px;
      margin-bottom: 35px; }
      .project .project-section .content-block .content p.big {
        font-family: "Lora", serif;
        font-size: 35px;
        line-height: 40px; }

.project .project-section.content-only .content {
  margin: 100px 50px; }

.project .project-section.extra-padding .container {
  padding-top: 75px;
  padding-bottom: 75px; }

.project .testimonial {
  padding: 100px 0; }
  .project .testimonial p {
    font-family: "Lora", serif;
    font-size: 35px;
    line-height: 45px;
    text-align: center; }
  .project .testimonial span.from {
    display: block;
    font-family: "Muli", sans-serif;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 4px;
    text-align: center;
    margin-top: 45px; }
  .project .testimonial .testimonial-container {
    max-width: 1120px;
    margin: 0 auto; }
  .project .testimonial.long p {
    font-size: 24px; }

@media (max-width: 768px) {
  .project .container {
    padding: 0;
    max-width: 100%;
    width: 100%; }
  .project .testimonial {
    padding: 100px 30px; } }

@media (max-width: 425px) {
  .project .project-section .content-block .content h3 {
    font-size: 25px;
    line-height: 32px; }
  .project .project-section .content-block .content p.big {
    font-family: "Lora", serif;
    font-size: 25px;
    line-height: 32px; }
  .project .project-section.content-only .content {
    margin: 50px; }
  .project .testimonial {
    padding: 100px 20px; }
    .project .testimonial p {
      font-size: 25px;
      line-height: 32px; }
    .project .testimonial span.from {
      padding: 50px 50px 0 50px; } }

.project.sample-project .project-header {
  background-color: #FEF8ED; }

.project.sample-project .testimonial {
  background-color: #4B606E;
  color: white; }

.project.aceandeverett .project-header {
  background-color: #F7F7F7; }

.project.aceandeverett .testimonial {
  background-color: #4B606E;
  color: white; }

.project.alphapoint .project-header {
  background-color: #FEF8ED; }

.project.alphapoint .testimonial {
  background-color: #4B606E;
  color: white; }

.project.ardeapartners .project-header {
  background-color: #F8F8F8; }

.project.ardeapartners .testimonial {
  background-color: #4B606E;
  color: white; }

.project.jamescole .project-header {
  background-color: #FFFFFF; }

.project.jamescole .testimonial {
  background-color: #4B606E;
  color: white; }

.project.metacoretechnologies .project-header {
  background-color: #F0F1F6; }

.project.metacoretechnologies .testimonial {
  background-color: #313767;
  color: white; }

.project.oatsovernight .project-header {
  background-color: #F7F7F7; }

.project.oatsovernight .testimonial {
  background-color: #71A746;
  color: white; }

.project.risecoldbrew .project-header {
  background-color: #FEF8ED; }

.project.risecoldbrew .testimonial {
  background-color: #4B606E;
  color: white; }

.project.thehighlight .project-header {
  background-color: #FEF8ED; }

.project.thehighlight .testimonial {
  background-color: #4B606E;
  color: white; }

.project.thehubapp .project-header {
  background-color: #F8F8F7; }

.project.thehubapp .testimonial {
  background-color: #4B606E;
  color: white; }

.project.mockingbird .mockingbird {
  background-color: #F4F6FC; }

.project.mockingbird .testimonial {
  background-color: #F4F6FC;
  color: #142A38; }

.project.vestal .project-header {
  background-color: white; }

.project.vestal .testimonial {
  background-color: #F0F1E3;
  color: #000000; }

.project.tembohealth .project-header {
  background-color: #FFFFFF; }

.project.tembohealth .testimonial {
  background-color: #4B606E;
  color: white; }

header {
  position: fixed;
  z-index: 99;
  width: 100%;
  transition: .6s all; }
  header .navbar {
    font-family: "Muli", sans-serif; }
    header .navbar .nav-item {
      text-transform: uppercase;
      margin-left: 50px;
      font-size: 12px;
      font-weight: 100;
      line-height: 18px;
      letter-spacing: 2px; }
      header .navbar .nav-item a {
        display: inline-block;
        padding: 25px 0; }
        header .navbar .nav-item a:hover {
          color: black; }
      header .navbar .nav-item.with-image a {
        padding: 15px 0; }
    header .navbar .navbar-toggler img {
      height: 26px; }
    header .navbar .navbar-brand img {
      transition: .3s all; }
    header .navbar #navbarNav {
      padding-right: 25px; }
  header.scrolled {
    background-color: #FFFFFF; }
    header.scrolled .navbar .navbar-brand img {
      height: 48px; }

.mobile-menu {
  background-color: #E6ECF0;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 100;
  opacity: 0;
  display: none; }
  .mobile-menu .flex-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 100%;
    padding: 20px 30px 30px 30px;
    justify-content: space-between; }
    .mobile-menu .flex-container .logo {
      max-height: 50px; }
    .mobile-menu .flex-container .close {
      height: 22px;
      margin-top: 10px;
      cursor: pointer; }
    .mobile-menu .flex-container .middle a {
      display: block;
      font-size: 40px;
      margin: 15px 0; }

@media (max-width: 425px) {
  header .navbar .navbar-brand img {
    height: 48px; } }

@media (max-width: 768px) {
  header .navbar .navbar-brand img {
    height: 48px; } }

@media (max-width: 1024px) {
  header .navbar .navbar-brand img {
    height: 48px; } }

@media (min-width: 1025px) {
  header .navbar .navbar-brand img {
    height: 80px; } }

.footer {
  background: #e6ecf0; }
  .footer .inner {
    padding: 100px 20px;
    text-align: center; }
    .footer .inner h3 {
      font-size: 35px;
      margin: 24px 0; }
    .footer .inner p {
      font-size: 20px;
      line-height: 30px;
      font-family: "Muli", sans-serif;
      margin-bottom: 50px; }
    .footer .inner .letsworktogether-container {
      max-width: 500px;
      margin: 0 auto; }

@media (max-width: 425px) {
  .footer .inner h3 {
    font-size: 25px;
    margin: 25px 0; } }

/*
TEMPLATE:
@media (max-width: 425px) {
}
@media (min-width: 426px) and (max-width: 768px) {
}
@media (min-width: 769px) and (max-width: 1024px) {
}
@media (min-width: 1025px) {
}
*/
.lightbox-background {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 200;
  display: none; }

.lightbox-container {
  display: none;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 201; }
  .lightbox-container .inner {
    margin: 0 auto;
    max-width: 1120px;
    padding: 50px 0;
    height: 100%; }
    .lightbox-container .inner .close {
      background: url("/assets/img/icon_x.png");
      background-size: contain;
      width: 18px;
      height: 18px;
      position: absolute;
      top: 50px;
      right: 50px; }
    .lightbox-container .inner .flex-container {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      height: 100%;
      margin: 50px 10px 0 10px; }
    .lightbox-container .inner .images {
      flex-grow: 99;
      margin: 0 auto;
      width: 100%;
      position: relative; }
      .lightbox-container .inner .images .image {
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        height: 100%;
        position: absolute;
        width: 100%;
        opacity: 0;
        transition: opacity 1s; }
        .lightbox-container .inner .images .image.active {
          opacity: 1; }
    .lightbox-container .inner .text-scroller {
      flex-grow: 1;
      margin: 20px auto;
      max-width: 900px;
      display: flex; }
      .lightbox-container .inner .text-scroller .left {
        flex-grow: 1;
        cursor: pointer; }
        .lightbox-container .inner .text-scroller .left img {
          margin-top: 35px; }
      .lightbox-container .inner .text-scroller .right {
        flex-grow: 1;
        text-align: right;
        cursor: pointer; }
        .lightbox-container .inner .text-scroller .right img {
          margin-top: 35px; }
      .lightbox-container .inner .text-scroller .content {
        flex-grow: 4;
        margin: 0 20px;
        overflow: hidden; }
        .lightbox-container .inner .text-scroller .content h4 {
          font-weight: 100; }
        .lightbox-container .inner .text-scroller .content p {
          line-height: 23px;
          font-size: 14px;
          font-family: "Muli", sans-serif;
          padding: 1px; }
        .lightbox-container .inner .text-scroller .content .body {
          text-align: center;
          display: inline-block;
          vertical-align: top; }

@media (max-width: 425px) {
  .lightbox-container .inner .close {
    top: 50px;
    right: 50px; }
  .lightbox-container .inner .flex-container {
    margin: 50px 10px 0 10px; } }

.related-project {
  /* Images */
  /* All Other Stuff */
  display: block;
  background-color: #F8F8F7; }
  .related-project .image {
    width: 144px;
    height: 144px;
    background-size: 100% 100%;
    background-position: center center;
    transition: all 1s;
    display: inline-block;
    vertical-align: middle; }
  .related-project.aceandeverett .image {
    background-image: url("/assets/img/projects/aceandeverett/aceandeverett_mainimage.jpg");
    background-position: top center; }
  .related-project.alphapoint .image {
    background-image: url("/assets/img/projects/oatsovernight/oatsovernight_mainimage.jpg"); }
  .related-project.ardeapartners .image {
    background-image: url("/assets/img/projects/ardeapartners/ardeapartners_thumbnail.jpg"); }
  .related-project.jamescole .image {
    background-image: url("/assets/img/projects/jamescole/jamesportfolio_thumbnail.jpg"); }
  .related-project.metacoretechnologies .image {
    background-image: url("/assets/img/projects/metacoretechnologies/metacore_website_mockup.jpg"); }
  .related-project.oatsovernight .image {
    background-image: url("/assets/img/projects/oatsovernight/oatsovernight_mainimage.jpg"); }
  .related-project.risecoldbrew .image {
    background-image: url("/assets/img/projects/risecoldbrew/rise_lift.jpg"); }
  .related-project.thehighlight .image {
    background-image: url("/assets/img/projects/oatsovernight/oatsovernight_flatlay.png"); }
  .related-project.thehubapp .image {
    background-image: url("/assets/img/projects/thehubapp/hubapp_iphone.png"); }
  .related-project.mockingbird .image {
    background-image: url("/assets/img/projects/mockingbird/mockingbird_mainimage.png"); }
  .related-project.vestal .image {
    background-image: url("/assets/img/projects/vestal/vestal_mainimage.png"); }
  .related-project.tembohealth .image {
    background-image: url("/assets/img/projects/tembohealth/tembohealth_mainimage.jpg"); }
  .related-project.tost .image {
    background-image: url("/assets/img/projects/tost/tost_top.png"); }
  .related-project.vedawarrior .image {
    background-image: url("/assets/img/projects/vedawarrior/veda_mainimage.jpg"); }
  .related-project.moxskincare .image {
    background-image: url("/assets/img/projects/mox/mox_mainimage.jpg"); }
  .related-project .body {
    padding: 0 0 0 30px;
    display: inline-block;
    vertical-align: middle; }
    .related-project .body .title {
      font-size: 30px;
      display: block;
      margin-bottom: 20px; }
      .related-project .body .title:after {
        content: ' ';
        background-image: url("/assets/img/icon_line.png");
        background-size: cover;
        display: block;
        width: 0%;
        height: 8px;
        transition: ease all 0.5s; }
    .related-project .body .link {
      display: block;
      text-transform: uppercase;
      font-family: "Muli", sans-serif;
      letter-spacing: 1px; }
  .related-project:hover {
    text-transform: none;
    color: black; }
    .related-project:hover .image {
      background-size: 125% 125%; }
    .related-project:hover .body .title:after {
      width: 100%; }

.related-projects .container {
  max-width: 1120px; }

.related-projects h2 {
  display: block;
  font-size: 35px;
  text-align: center;
  padding: 40px 0; }

@media (max-width: 768px) {
  .related-project {
    background: none; }
    .related-project .image {
      width: 220px;
      height: 220px; }
    .related-project .body {
      padding: 20px 0;
      display: block; }
      .related-project .body .title {
        font-size: 20px;
        text-align: center; }
        .related-project .body .title:after {
          display: none; }
      .related-project .body .link {
        display: none; }
  .related-projects .col-6:nth-child(1) .related-project {
    float: right; }
  .related-projects .col-6:nth-child(2) .related-project {
    float: left; } }

@media (max-width: 425px) {
  .related-project .image {
    width: 156px;
    height: 156px; }
  .related-project .body .title {
    font-size: 16px; } }
